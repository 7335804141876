import type { FunctionComponent } from "react";
import type { ProductFlatten } from "~/types";
import { isBlackFridayPromoEnabled } from "~/utils/dates";
import Neum from "./neum.component";

const ProductCard: FunctionComponent<{ product: ProductFlatten; type: string }> = ({ product, type }) => {
  const showDiscount = isBlackFridayPromoEnabled();

  const originalPrice = Math.ceil(product.priceRange.minVariantPrice.amount * 1.25);
  const discountedPrice = Math.round(product.priceRange.minVariantPrice.amount * 0.85 * 100) / 100;

  if (type === "card") {
    return (
      <Neum className="h-full w-full p-1 sm:p-2">
        <div className="p-0">
          <a href={`/products/${product.handle}`}>
            <img
              className="h-28 w-full rounded-b-none rounded-t-lg object-cover sm:h-44"
              src={`${product.images[0].transformedUrl}&width=300`}
              alt={product.featuredImage?.altText ?? ""}
            />
            <div className="relative bottom-0 left-0 z-10 w-full p-0 px-3 pb-0  pb-4  sm:relative">
              <div className="h-xs-info-card sm:h-s-info-card">
                <h2 className="my-2 h-8 text-left text-xs font-semibold line-clamp-2 dark:text-shadow-xl sm:my-4 sm:mb-0 sm:text-lg md:h-14 md:font-bold">
                  {product.title}
                </h2>
                <p className="mb-5 mt-0 text-xs font-light text-admirerBlack-700 line-clamp-2 sm:text-sm">
                  {product.description}
                </p>
              </div>
              <p className=" pt-0 text-right text-xs font-light dark:text-white">
                Original price: <span className="original-price text-xs line-through">$ {originalPrice}</span>
              </p>
              <p className="text-right text-base dark:text-shadow-xl sm:text-lg md:-mb-4 md:pb-2 md:leading-9">
                <span id="value" className={showDiscount ? "line-through" : ""}>
                  ${product.priceRange.minVariantPrice.amount}
                </span>
                {showDiscount && (
                  <span id="discount-value" className="text-red-600">
                    {` $${discountedPrice} `}
                  </span>
                )}
                <span className="text-xs sm:text-sm">{product.priceRange.minVariantPrice.currencyCode}</span>
              </p>
            </div>
          </a>
        </div>
      </Neum>
    );
    //El else es para cuando se utilice el neum component con el diseño nuevo de las galerias en mobile
  }
  return (
    <Neum className="h-full w-full p-2 sm:h-full">
      <div className="h-full p-0">
        <a href={`/products/${product.handle}`}>
          <img
            className="h-64 w-full rounded-lg object-cover sm:h-48 sm:rounded-b-none"
            src={`${product.images[0].transformedUrl}&width=300`}
            alt={product.featuredImage?.altText ?? ""}
          />
          <div className="absolute bottom-0 left-0 z-10 w-full p-4 pb-4 sm:relative sm:p-0 sm:pb-0">
            <div className="flex h-20 items-center justify-center rounded-xl bg-admirerBlue-900 bg-opacity-50 py-1 pb-1 backdrop-blur-md sm:bg-transparent sm:px-3 sm:pb-4 sm:backdrop-blur-none md:h-full md:pt-1">
              <div>
                <h2 className="px-8 text-center text-xs text-white line-clamp-2 dark:text-white dark:text-shadow-xl sm:my-4 sm:mb-0 sm:text-left sm:text-base sm:font-bold sm:leading-tight md:h-11 md:px-0 md:text-admirerBlack-900 md:line-clamp-2">
                  {product.title}
                </h2>
                <div className="hidden sm:block">
                  <p className="mb-5 mt-0 text-sm font-light text-admirerBlack-700 line-clamp-2">
                    {product.description}
                  </p>
                </div>
                <p className="hidden pt-0 text-right text-xs font-light text-admirerBlack-900 dark:text-white sm:block">
                  Original price: <span className="original-price text-xs line-through">$ {originalPrice}</span>
                </p>
                <p className="text-md text-center text-white dark:text-white dark:text-shadow-xl sm:text-right md:text-admirerBlack-900">
                  <span id="value" className={showDiscount ? "line-through" : ""}>
                    ${product.priceRange.minVariantPrice.amount}
                  </span>
                  {showDiscount && (
                    <span id="discount-value" className="text-red-500 sm:text-red-600">
                      {` $${discountedPrice} `}
                    </span>
                  )}
                  <span className="pl-1 text-xs uppercase">{product.priceRange.minVariantPrice.currencyCode}</span>
                </p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </Neum>
  );
};

export default ProductCard;
